import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whiskyDateRange'
})
export class WhiskyDateRangePipe implements PipeTransform {

  transform(whiskies: any[], startDate: Date, endDate: Date): any[] {
    return whiskies.filter(it => {
      return this.inRange(new Date(it.meeting_date), startDate, endDate);
    });
  }

  inRange(date: Date,startDate: Date, endDate: Date) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
   return (
        startDate <= date && date <= endDate 
    );
}

}
