
<div class="content">
  <p>
    sMac is a small whisky club that pools our resources to buy and taste single malt whiskies. We have an awesome time doing it, and we share are results here with you.
    </p>
    <p>We have a simple system of 0-10 where 5 is a whisky you would 'buy again'.</p>
  

  <h2 class="mat-h2">At our Last Meeting</h2>
  <div class="icon-text" fxLayoutGap="16px">
    <mat-icon>location_on</mat-icon>
    <span class="body-1">{{last_meeting_location}}</span>
  </div>
  <div class="icon-text" fxLayoutGap="16px">
    <mat-icon>event_available</mat-icon>
    <span class="body-1">{{last_meeting_date}}</span>
  </div>
</div>

<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
  <app-whisky-card fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" 
  *ngFor="let whisky of last_meeting_whiskies"
  [whisky]="whisky">
  </app-whisky-card>
</div>

<div class="content">
  <h2 class="mat-h2">In the last year, our top 4 whiskies were these...</h2>
</div>

<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
  <app-whisky-card fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" 
  *ngFor="let whisky of recentTopWhiskies | whiskySort:selectedSortType:sortDirectionAscending | slice:0:4"
  [whisky]="whisky">
  </app-whisky-card>
</div>