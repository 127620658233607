

 
<div class="whisky-search" fxLayout="row">

        <mat-form-field appearance="fill" class="seachAnything">
            <mat-label>Seach Anything</mat-label>
            <input matInput placeholder="Seach Anything" [(ngModel)]="searchFish">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>


        <mat-form-field appearance="fill" class="sortAnything">
            <mat-label>Sort By:</mat-label>
            <mat-select [(ngModel)]="selectedSortType" name="food">
                <mat-option *ngFor="let sortType of sortTypes" [value]="sortType.param">
                {{sortType.param}}
                <!-- <mat-icon>swap_vert</mat-icon> -->
                <!-- <span *ngIf="sortType.param == selectedSortType"><mat-icon matSuffix>swap_vert</mat-icon></span> -->
                <!-- <mat-icon *ngIf="sortType.param == selectedSortType" matSuffix>swap_vert</mat-icon> -->
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-icon-button color="white" aria-label="Swap Search Dir" class="swapDirectionButton" (click)="sortDirectionAscending= !sortDirectionAscending">
            <mat-icon *ngIf="sortDirectionAscending" >text_rotate_up</mat-icon>
            <mat-icon *ngIf="!sortDirectionAscending">text_rotation_down</mat-icon>
        </button>

</div>


<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
    <app-whisky-card fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" 
    *ngFor="let whisky of whiskies | whiskySearch:searchFish | whiskySort:selectedSortType:sortDirectionAscending"
    [whisky]="whisky">
    </app-whisky-card>
</div>