import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-whisky-card',
  templateUrl: './whisky-card.component.html',
  styleUrls: ['./whisky-card.component.css']
})
export class WhiskyCardComponent implements OnInit {

  constructor() { }

  @Input() whisky: any; //TODO define type.
  
  ngOnInit(): void {
  }

}
