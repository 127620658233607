<!-- <div class="col-md-12">
    <mat-card>
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <form
        *ngIf="!isLoggedIn"
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            class="form-control"
            name="username"
            [(ngModel)]="form.username"
            required
            #username="ngModel"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && username.invalid"
          >
            Username is required!
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            [(ngModel)]="form.password"
            required
            minlength="6"
            #password="ngModel"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && password.invalid"
          >
            <div *ngIf="password.errors.required">Password is required</div>
            <div *ngIf="password.errors.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block">
            Login
          </button>
        </div>
        <div class="form-group">
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="f.submitted && isLoginFailed"
          >
            Login failed: {{ errorMessage }}
          </div>
        </div>
      </form>
  
      <div class="alert alert-success" *ngIf="isLoggedIn">
        Logged in as {{ roles }}.
      </div>
    </mat-card>
  </div> -->


  <mat-card *ngIf="!isLoggedIn">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h2>Log In</h2>
        <mat-error *ngIf="isLoginFailed">
          Login failed: {{ errorMessage }}
        </mat-error>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="username" formControlName="username" required>
          <mat-error>
            Please provide a valid name
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error>
            Please provide a valid password
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="accent">Login</button>
      </form>
    </mat-card-content>
  </mat-card>