import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whiskySort'
})
export class WhiskySortPipe implements PipeTransform {

  transform(items: [], column: string, directionAscending: boolean): any[] {
    let sortedItems = [];

    if(!items) {
      return [];
    }
    
    if(!column) {
      return items;
    }

    sortedItems = directionAscending ? this.sortAscending(items, column) :
                                    this.sortDescending(items, column);

    return sortedItems;
  }


  //TODO: For strings, convert to upper or lower case before comparison. Use isNaN?
  sortAscending(items, column) {
    return [...
      items.sort(function(a:any, b:any) {
        // An order by number comparator that orders nulls or empty contents below real numbers
        // If we don't get strings, just compare by index
        if ((a[column] == null) || (a[column] == 'null') ){  
          //note that this checks forundefined also due to javascript coercion.
          // if v1 is null or undefined, we put it at the end. Knowing of v2 is null doesn't make a difference
          return 1;
        } else if ((b[column] == null) || (b[column] == 'null') ){ 
          return -1;
        } else {
          let c;
          let d;

          if ((typeof a[column] === 'string') || (a[column] instanceof String))
            c = a[column];
          else
            c = a[column].toString();

          if ((typeof b[column] === 'string') || (b[column] instanceof String))
            d = b[column];
          else
            d = b[column].toString();

          return (c < d) ? -1 : 1;  
          // else
          //   return (a[column] < b[column]) ? -1 : 1; 
        }
    })]
  }

  sortDescending(items, column) {
    return [...
      items.sort(function(a:any, b:any) {
        // An order by number comparator that orders nulls or empty contents below real numbers
        // If we don't get strings, just compare by index
        if ((a[column] == null) || (a[column] == 'null') ){  
          //note that this checks forundefined also due to javascript coercion.
          // if v1 is null or undefined, we put it at the end. Knowing of v2 is null doesn't make a difference
          return 1;
        } else if ((b[column] == null) || (b[column] == 'null') ){ 
          return -1;
        } else {
          let c : string;
          let d : string;
          
          if ((typeof a[column] === 'string') || (a[column] instanceof String))
            c = a[column];
          else
            c = a[column].toString();
          if ((typeof b[column] === 'string') || (b[column] instanceof String))
            d = b[column];
          else
            d = b[column].toString();
          
            return (c.toUpperCase() > d.toUpperCase()) ? -1 : 1;  
        }
    })]
  }

}
