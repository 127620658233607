import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  //TODO update the API and this code for the API to return a 401, and then we do not need the check for data.success
  onSubmit(): void {
    if(this.form.valid) {
      const username = this.form.get('username').value;
      const password = this.form.get('password').value;
      this.authService.login(username, password).subscribe(
        data => {
          if (data.success) {
            this.tokenStorage.saveToken(data.token);
            this.tokenStorage.saveUser(data);

            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.roles = this.tokenStorage.getUser().roles;
            this.reloadPage();
           } else if (data.error){
            this.errorMessage = data.error;
            this.isLoginFailed = true;
          }
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
          console.log("login error!!!");
        }
      );
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
