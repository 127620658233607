import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// import {ScrollingModule} from '@angular/cdk/scrolling';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { WhiskiesComponent } from './whiskies/whiskies.component';
import { MeetingsComponent } from './meetings/meetings.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from "@angular/flex-layout";

import { MatToolbarModule } from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';




import { WhiskySearchPipe } from './pipes/whisky-search.pipe';
import { WhiskySortPipe } from './pipes/whisky-sort.pipe';

import { WhiskyCardComponent } from './partials/whisky-card/whisky-card.component';
import { WhiskyDateRangePipe } from './pipes/whisky-date-range.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    WhiskiesComponent,
    WhiskySearchPipe,
    WhiskySortPipe,
    WhiskyCardComponent,
    WhiskyDateRangePipe,
    MeetingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // ScrollingModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatSidenavModule
  ],
  providers: [authInterceptorProviders, WhiskyDateRangePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
