import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-whiskies',
  templateUrl: './whiskies.component.html',
  styleUrls: ['./whiskies.component.css']
})
export class WhiskiesComponent implements OnInit {

  whiskies: [];
  searchFish: string;

  sortTypes = [
    { param: 'distillery', name: 'distillery' },
    { param: 'age', name: 'age' },
    { param: 'name', name: 'name' },
    { param: 'club_rating_average', name: 'average' },
  ];
  selectedSortType  = this.sortTypes[0].param; // set the default sort type
  sortDirectionAscending : boolean = true;

  constructor(private userService: UserService) { }

  //TODO: Should we do the pipe search on change rather than in the templete for efficiency. https://angular.io/guide/pipes

  //TODO do we need to unsubsribe?
  ngOnInit(): void {
    this.userService.getWhiskies().subscribe(
      data => {
        this.whiskies = data.result;
        console.log(this.whiskies);
      },
      err => {
        //TODO something better here
        //this.meetings = JSON.parse(err.error).message;
        this.whiskies=[];
        console.log("Failed to retrieve data on Home page");
      }
    );
  }

}
