<div class="content" fxLayout="row" >
    <div class="meeting-nav"  fxFlex="25%" fxFlex.sm="33%" fxFlex.xs="50%" >
        <div class="meeting-nav-inner">
            <div class="meeting-nav-item" *ngFor="let meeting of meetings"  (click)="selectMeeting(meeting)"
            [ngClass]="{'meeting-nav-item-highight': meeting.meeting.id_meeting === selected_meeting.meeting.id_meeting}">
                <a>
                <div class="date">{{meeting.meeting.meeting_date}} </div>
                <div class="meeting-item-location">
                    <mat-icon class="label-icon" color="accent">location_on</mat-icon>
                    <span>{{meeting.meeting.meeting_location}} </span>
                </div>
                </a>
            </div>
        </div>
    </div>
    <div class="meeting-content" >
        <div class="meeting-nav-inner">
        <div class="scroll" fxLayout="column" fxLayoutGap="16px grid">
            <app-whisky-card fxFlex="80%"
            *ngFor="let whisky of selected_meeting_whiskies"
            [whisky]="whisky">
            </app-whisky-card>
        </div>
    </div>

    </div>
</div>



<!-- <div class="content" fxLayout="row" fxLayoutGap="16px">
    <div class="meeting-nav"  fxFlex="25%">
        <cdk-virtual-scroll-viewport itemSize="50" fxFlex class="scroll-viewport">
            <div *cdkVirtualFor="let meeting of meetings"  (click)="selectMeeting(meeting)">
                <a>
                {{meeting.meeting.meeting_date}} 
                {{meeting.meeting.meeting_location}} 
            </a>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
    <div class="meeting-content" fxFlex="65%">
        <div class="scroll" fxLayout="column" fxLayoutGap="16px grid">
            <app-whisky-card fxFlex="80%"
            *ngFor="let whisky of selected_meeting_whiskies"
            [whisky]="whisky">
            </app-whisky-card>
        </div>

    </div>
</div> -->